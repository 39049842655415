.minute {
  position: relative;
  top: 0px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  width: 100%;
  background-color: #FFA800;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.play-area {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  height: 100%;
}