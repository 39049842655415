.swap-confirm {
    width: 76vw;
}

.swap-player-selected {
    border-radius: 6vh;
    box-shadow: 0 0 10px 5px #0ff;
    transform: translateY(-45px);
}

.swap-player {
    margin-right: 5vw;
    margin-left: 5vw;
}