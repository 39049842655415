.plain-section {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  height: 30vh;
  width: 100%;
  overflow: auto;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.25);
  border-color: rgb(219, 219, 219);
  border-style: solid;
  border-width: 2px;
  border-radius: 12px;
  margin-bottom: 2vh;
}