.title-container {
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
}

.welcome-text {
    text-align: center;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}


/* @import url('https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css'); */
.firebaseui-idp-password {
    background: var(--color-primary) !important;
}

.mdl-button--colored {
    background: var(--color-primary) !important;
}

.firebaseui-button, .firebaseui-idp-button {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    font-style: normal !important;
    font-weight: normal !important;
    min-width: 50% !important;
}

.firebaseui-id-secondary-link {
    background: #FFFFFF !important;
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

.firebaseui-idp-google {
    border: 2px solid var(--color-primary) !important;
}