.strategy-card {
    box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 68.88px;
    height: 81.91px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column wrap;
    text-align: center;
    background: rgba(136, 60, 163, 0.25);
    box-sizing: border-box;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);

}

.strategy-card-border {
    border: 1px solid #883CA3;
}