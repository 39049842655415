.dice1 {
    color: red;
}

.dice2, .dice3, .dice4, .dice5 {
    color: var(--color-primary);
}

.dice6 {
    color: green;
}