.player-attribute {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  min-width: 40px;
}

.floating-operator {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  line-height: 18px;
  transform: translateX(-9px) translateY(-9px);
  background: #FFFFFF;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  color: black;
  box-sizing: border-box;
}

.player-attack {
  color: var(--color-attack);
}

.player-defense {
  color: var(--color-defense);
}

.player-score {
  background-color: var(--color-primary);
  color: white;
}

.player-bonus {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-x: show;
  right: 1vw;
  top: 2vw;
}

.player-fatigue {
  color: red;
}

.player-inspiration {
  color: green;
}

.player-padding {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

.round-players {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.round-players::-webkit-scrollbar {
  display: none;
}