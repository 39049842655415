.pack {
    box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.25);
    /* width: 29vh; */
    height: 61vh;
    position: relative;
    margin-right: 12vw;
    margin-left: 12vw;
}

.pack-card {
    margin-right: 12vw;
    margin-left: 12vw;
}

.pack-main-title {
    font-family: Graduate;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5vh;
    line-height: 4vh;
    text-align: center;
    color: #000000;
    position: absolute;
    top: 9vh;
}

.pack-name {
    position: absolute;
    bottom: 0;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2vh;
    line-height: 3vh;
    /* identical to box height */
    text-align: center;
    color: #000000;
    width: 100%;
}