.round-total-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 4px;
}

.round-total-score {
    font-weight: bold;
    color: var(--color-primary);
}