.helpTextContainer {
    position: fixed;
    left: 5vw;
    width: 90vw;
    z-index: 1;
}

.childElements {
    position: relative;
    z-index: 1;
}

.helpContainer {
    flex-grow: 0;
    z-index: 1;
    background-color: unset;
}