.away-box {
    background-color: lightblue;
    width: 100%
}

.away-score-box {
    background-color: blue;
    padding: 10px;
    color: white;
    width: 12vw;
}

.home-box {
    background-color: salmon;
    width: 100%
}

.home-score-box {
    background-color: red;
    padding: 10px;
    color: white;
    width: 12vw;
}

.score-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    flex-grow: 0;
}

.team-name {
    padding: 10px;
    flex-grow: 1;
    text-align: left;
}

.scorers-container {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: small;
}