.full-card-avatar {
  width: 36vh;
  height: 36vh;
  position: absolute;
  left: 0;
  top: 0;
  
  /* z-index: -1; */
}

.full-card-avatar-container {
  width: 36vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 36vh;
}



.player-card {
  box-shadow: 0px 7px 18px rgba(0, 0, 0, 0.25);
  border-radius: 2vh;
  width: 24vh;
  height: 32vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;

}

.attackAttribute {

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 3vh;
  text-align: center;
  color: #00BF2A;
  flex-flow: column wrap;
}

.defenseAttribute {

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 3vh;
  text-align: center;
  color: #FF0000;
  flex-flow: column wrap;
}

.attribute {


  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2vh;
  line-height: 3vh;
  text-align: center;
  flex-flow: column wrap;
}

.attribute-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}