.mini-card-container {
    width: 12.8vmin;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.mini-card {
    width: 12vmin;
    height: 12vmin;
    border-radius: 2vmin;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}


.mini-card-avatar-container {
    width: 12vmin;
    height: 12vmin;
    text-align: left;
}

.mini-card-avatar {
    width: 12vmin;
    height: 12vmin;
    border-radius: 1.6vmin;
    position:absolute;
    top: 0.4vmin;
}

.mini-card-attribute-container {
    background: #FFFFFF;
    width: fit-content;
    border-radius: 1vmin;
}



.mini-card-attack-defense-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 3vh;
    height: 1.8vh;
}


.mini-card-attribute-defense {
    font-weight: 500;
    font-size: 1.4vh;
    line-height: 2vh;
    text-align: center;
    color: var(--color-defense);

}

.mini-card-attribute-attack {
    font-weight: 500;
    font-size: 1.4vh;
    line-height: 2.0vh;
    text-align: center;
    color: var(--color-attack);

}