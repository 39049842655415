.textInput {
  background: #FFFFFF;
  border: 2px solid #00C259;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 56px;
  width: 100%;
  text-align: center;
  outline: none;
}

.textInput:focus {
  box-shadow: 0 0 3pt 2pt orange;
}