.button {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-family: Graduate;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 56px;
    width: 100%;
}

.button-disabled {
    opacity: 0.4;
}

.primary {
    background: var(--color-primary);
    color: #FFFFFF;
}

.secondary {
    background: #FFFFFF;
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.MuiLoadingButton-root{
    width: 100%;
}
