.host--dark {
  font-family: 'Graduate', cursive;
  font-size: 36px;
  font-weight: 500;
  color: #000;
}

.host--light {
  font-family: 'Graduate', cursive;
  font-size: 36px;
  font-weight: 500;
  color: #FFF;
}