.help-text {
    background-color: rgb(229, 242, 255);
    border-color: rgb(40, 102, 246);
    border-style: solid;
    border-width: 3px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 17px;
    display: block;
}