.commentary-container {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0px;
}

.commentary-entry-title {
  font-weight: bolder;
}

.commentary-entry {
  border-top-color: rgb(219, 219, 219);
  border-top-style: solid;
  border-top-width: 1px;
  padding-bottom: 24px;
  padding-top: 24px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 20px;
}

.commentary-entry-goal {
  background-color: rgb(229, 242, 255);
  border-left-color: rgb(40, 102, 246);
  border-left-style: solid;
  border-left-width: 3px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
}

.commentary-entry-time {
  font-weight: bold;
}

.commentary-entry-kickoff {
  background-color: rgb(229, 242, 255);
  border-left-color: rgb(40, 102, 246);
  border-left-style: solid;
  border-left-width: 3px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
}


.commentary-entry-highlight {
  font-weight: bold;
}

.round-link {
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  text-decoration-line: underline;
  color: #2787CC;
}

.type-icon {
  position: relative;
  right: 0;
  top: 0;
}