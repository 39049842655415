.strategy-card-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.strategy-card-container::-webkit-scrollbar {
  display: none;
}

.strategy-card-padding {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  box-sizing: border-box;
  border-radius: 4px;
}

.strategy-card-description {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}