 .cards-container {
     overflow-x: auto;
     /* width: 100vw; */
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-items: center;
     margin: 0;
     padding: 0;
     max-width: 100vw;
     min-width: 1px;
     list-style-type: none;
 }

 .card-container {

     position: -webkit-sticky;
     /* Safari */
     position: sticky;
 }

 .cover-container {
     position: relative;
     left: 50%;
     transform: translateX(-50%);
 }


 .placeholder {
     width: 50vw;
     flex-shrink: 0;
     height: 100%;
 }